<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <h1>Create Permission</h1>
  <div class="p-grid">
    <div class="p-col-12 p-sm-6">
      <PermissionForm @submitted="create" />
    </div>
  </div>
</template>

<script type="ts">
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast.js'
import PermissionForm from '@/components/PermissionForm.vue'
import { getNewKey } from '@/helpers/firebase.ts'
import { db } from '@/services/firebase.ts'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default {
  components: { PermissionForm, BaseBreadcrumb },
  setup() {
    const toast = useToast()
    const router = useRouter()
    const breadcrumbs = [
      { label: 'Permissions', to: '/permissions' },
      { label: 'Create' }
    ]

    function create(formData) {
      const newKey = getNewKey('permissions')

      db.ref('permissions/' + newKey)
        .set(formData)
        .then(() => {
          router.push({ name: 'PermissionIndex' })
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Error creating permission',
            detail: err.message,
            life: 3000
          })
        })
    }

    return { create, breadcrumbs }
  }
}
</script>
